import { IntlProvider } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Container, Flex, Stack, StackDivider } from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { FormColorTheme } from '~shared/types'

import { useIndividualSubmission } from '~pages/Citizen/Submissions/ViewSubmissions/queries'
import { SubmissionData } from '~features/public-form/PublicFormContext'

import { LanguageFilter } from '../../../../../atom'

import { PayNowPage } from './PayNowPage'

export const PayAgainPage = (): JSX.Element => {
  const { formId, submissionId } = useParams()

  if (!formId) throw new Error('Missing formId')
  if (!submissionId) throw new Error('Missing submissionId')

  const { data, isLoading, isError } = useIndividualSubmission()
  const locale: any = useRecoilValue(LanguageFilter)
  const submissionData: SubmissionData = {
    id: submissionId,
    /** Submission time (on browser)  */
    timeInEpochMs: 0,
    status: data?.submissionStatus,
    paymentRequired: data?.paymentRequired,
    paymentCharges: data?.paymentCharges,
    paymentAmount: data?.paymentAmount,
  }

  return (
    <IntlProvider locale={locale}>
      <Container w="42.5rem" maxW="100%" p={0}>
        {data ? (
          <PayNowPage
            colorTheme={FormColorTheme.Blue}
            submissionData={submissionData}
            paymentAmount={submissionData.paymentAmount}
            paymentRequired={submissionData.paymentRequired}
            buttonText={`${
              locale === 'en'
                ? 'Pay Now'
                : locale === 'si'
                ? 'දැන් ගෙවන්න'
                : 'இப்போது செலுத்த'
            }`}
            navigateToDashboard={true}
          />
        ) : null}
      </Container>
    </IntlProvider>
  )
}
